import { toLower } from 'lodash-es';
import { parseToReadableDate } from './DateTimeHelpers';

export const translateChallengeTypes = (type) => {
  switch (type) {
    case 'GroupFixedReward':
      return 'Groep fixed';
    case 'IndividualPercentagePointReward':
    case 'IndividualFixedReward':
      return 'Individueel fixed';
    case 'GroupVariableReward':
      return 'Groep variabel';
    case 'IndividualVariableReward':
      return 'Individueel variabel';
    case 'TriggerReward':
      return 'Trigger';
    default:
      return type;
  }
};

export const translateMenuButton = (buttonText) => {
  switch (buttonText) {
    case 'challenges':
      return 'Challenges';
    case 'participants':
      return 'Deelnemers';
    case 'voting':
      return 'Stemrecht';
    default:
      return buttonText;
  }
};

export const translateCustomerStatus = (text) => {
  switch (text) {
    case 'CIVIL_SERVANT':
      return '(Federaal) ambtenaar';
    case 'STAMPING_JOB_SEEKER':
      return 'Stempelend werkzoekend';
    case 'SOCIAL_EMPLOYMENT':
      return 'Sociale tewerkstelling';
    case 'REGULAR_EMPLOYMENT':
      return 'Reguliere arbeid';
    case 'LIVING_WAGE':
      return 'Leefloon';
    case 'SICKNESS_INVALIDITY_ALLOWANCE':
      return 'Ziekte- en/of invaliditeitsuitkering';
    case 'BRIDGE_RETIRED':
      return 'Bruggepensioneerd';
    case 'RETIRED':
      return 'Gepensioneerd';
    case 'UNEMPLOYED':
      return 'Geen regulier werk';
    case 'STUDENT':
      return 'Student';
    case 'ARTICLE60':
      return 'Artikel 60';
    default:
      return text;
  }
};

export const translateNotificationChannel = (text) => {
  switch (text) {
    case 'SMS_EMAIL':
      return 'Via sms en e-mail';
    case 'SMS':
      return 'Via sms';
    case 'EMAIL':
      return 'Via e-mail';
    default:
      return text;
  }
};

export const translateGender = (text) => {
  switch (text) {
    case 'male':
      return 'Man';
    case 'female':
      return 'Vrouw';
    default:
      return text;
  }
};

export const translatePriceTypes = (text) => {
  switch (text) {
    case 'LESSON':
      return 'Les';
    case 'ACTIVITY':
      return 'Activiteit';
    default:
      return text;
  }
};

export const translateNotificationEvent = (text) => {
  switch (text) {
    case 'CONFIRMATION':
      return 'Inschrijving bevestiging';
    case 'REMINDER':
      return 'Activiteit herinnering';
    case 'ACTIVITY_CANCELED':
      return 'Activiteit afgelast';
    case 'LESSON_CANCELED':
      return 'Les afgelast';
    default:
      return text;
  }
};

export const translateSettingType = (text) => {
  switch (text) {
    case 'domain':
      return 'domein';
    case 'organisation':
      return 'organisatie';
    case 'location':
      return 'locatie';
    case 'department':
      return 'departement';
    default:
      return text;
  }
};

export const translateNoteType = (text) => {
  switch (text) {
    case 'INFO':
      return 'opmerking';
    case 'WARNING':
      return 'waarschuwing';
    case 'EXPELL':
      return 'schorsing';
    default:
      return text;
  }
};

export const translateNoteKey = text => {
  switch (text) {
    case 'createdAt':
      return ['Aangemaakt op', parseToReadableDate];
    case 'createdBy':
      return ['Aangemaakt door'];
    case 'type':
      return ['Type', translateNoteType];
    case 'text':
      return ['Inhoud'];
    case 'date':
      return ['Startdatum', parseToReadableDate];
    case 'validUntil':
      return ['Vervaldatum', parseToReadableDate];
    case 'expired':
      return ['Verlopen', value => (value ? 'Ja' : 'Nee')];
    case 'updatedBy':
      return ['Laatst aangepast door'];
    case 'location.name':
      return ['Locatie'];
    default:
      return [text];
  }
};

export const translateApiError = (text) => {
  switch (text) {
    case 'Failed to authenticate user':
      return 'Mislukt om rechten te bepalen voor de ingelogde gebruiker';
    case 'User has no rights in BRaaS':
      return 'Gebruiker heeft geen rechten toegekend in BRaaS';
    default:
      return text;
  }
};

export const translateOrigin = (origin) => {
  switch (origin) {
    case 'LESSON_CANCELED':
      return 'Annulatie les';
    case 'ACTIVITY_REMINDER':
      return 'Herinnering activiteit';
    case 'ACTIVITY_CANCELED':
      return 'Annulatie activiteit';
    case 'MANUAL_TASK':
      return 'Manuele actie';
    case 'REGISTRATION_CONFIRMED':
      return 'Bevestiging registratie';
    default:
      return origin;
  }
};

export const translateStatus = (status) => {
  switch (status) {
    case 'ReceivedByWorker':
    case 'InProgress':
      return 'Bezig met verzenden';
    case 'Scheduled':
      return 'Gepland';
    case 'Unknown':
      return '/';
    case 'Succeeded':
      return 'Verzonden';
    default:
      return status;
  }
};

export const translateAuditLogType = (type) => {
  switch (type) {
    case 'create':
      return 'aangemaakt';
    case 'update':
      return 'aangepast';
    case 'delete':
      return 'verwijderd';
    default:
      return type;
  }
};

export const translateEntityType = (type) => {
  switch (toLower(type)) {
    case 'domain':
      return 'domein';
    case 'organisation':
      return 'organisatie';
    case 'department':
      return 'departement';
    case 'domaincontact':
      return 'domein contact';
    case 'organisation contact':
      return 'organisatie contact';
    case 'departementcontact':
      return 'departement contact';
    case 'locationcontact':
      return 'locatiecontact';
    case 'domainaddress':
      return 'domein adres';
    case 'organisationaddress':
      return 'organisatieadres';
    case 'departementaddress':
      return 'departement adres';
    case 'locationaddress':
      return 'locatie adres';
    case 'customeraddress':
      return 'klant adres';
    case 'location':
      return 'locatie';
    case 'classroom':
      return 'klas';
    case 'accessory':
      return 'accessoire';
    case 'visit':
      return 'vrije inloop';
    case 'customer':
      return 'klant';
    case 'admin':
      return 'administrator';
    case 'note':
      return 'opmerking';
    case 'registration':
      return 'inschrijving';
    case 'activity':
      return 'opleiding';
    case 'activitytype':
      return 'categorie';
    case 'lesson':
      return 'les';
    case 'attendance':
      return 'aanwezigheid';
    case 'teacher':
      return 'leerkracht';
    case 'notificationtemplate':
      return 'Notificatie op template';
    case 'notification':
      return 'notificatie';
    case 'applicant':
      return 'wachtlijst-inschrijving';
    case 'emailtask':
      return 'email';
    case 'smstask':
      return 'sms';
    case 'openingslot':
      return 'openingsuren locatie';
    default:
      return type;
  }
};


export const translateCustomerQuestion = (text) => {
  switch (text) {
    case 'ITSME':
      return 'ITSME';
    case 'BURGERPROFIEL':
      return 'Burgerprofiel';
    case 'MYMINFIN':
      return 'MyMinFin';
    case 'MYPENSION':
      return 'MyPension';
    case 'VDAB':
      return 'VDAB';
    case 'LOCAL_MUNICIPALITY_PROFILE':
      return 'Lokaal gemeente profiel';
    case 'ONLINE_BANKING':
      return 'Online bankieren';
    case 'OTHER':
      return 'Andere';
    default:
      return text;
  }
};
