
import React, { useEffect } from 'react';
import Contact from './Contact';

const Map = ({
  className = '',
  place,
  address,
  telephone,
}) => {
  let map = null;

  const initGoogleMap = () => {
    const digipolisLocation = { lat: 51.1947289, lng: 4.3823647 };

    // create map
    const googleMap = new window.google.maps.Map(map, {
      center: digipolisLocation,
      zoom: 15,
      mapTypeControl: false,
    });

    // fetch coordinates for location
    new window.google.maps.Geocoder().geocode({ address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        googleMap.setCenter(results[0].geometry.location);
        // eslint-disable-next-line no-new
        new window.google.maps.Marker({
          map: googleMap,
          position: results[0].geometry.location,
        });
      } else {
        // eslint-disable-next-line no-new
        new window.google.maps.Marker({
          map: googleMap,
          position: digipolisLocation,
        });
      }
    });
  };

  useEffect(() => {
    initGoogleMap();
  }, []);

  return (
    <div
      className={`c-map ${className}`}
    >
      <Contact
        className="c-map__contact u-hide@below-viewport-3"
        place={place}
        address={address}
        telephone={telephone}
      />

      <div
        ref={el => map = el}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default Map;
