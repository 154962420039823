import React, { useState } from 'react';
import cookie from 'react-cookies';
import { Route } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import LayoutPublic from '../../layouts/LayoutPublic';
import axiosWrapper from '../../api/shared/axiosWrapper';
import baseWebpRequest from '../../api/shared/baseWebpRequest';

const PublicRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(false);
  cookie.remove('Authorization');
  const jwt = cookie.load('jwt');

  if ((!jwt || decode(jwt).role !== 'public-user') && !loading) {
    setLoading(true);
    axiosWrapper.get(baseWebpRequest, '/publicToken', { proxy: false }).then(res => {
      cookie.save(
        'jwt',
        res.token,
        {
          sameSite: false,
          secure: true,
        },

      );
      setLoading(false);
    });
  }
  return (
    <Route
      {...rest}
      render={matchProps => (
        !loading && (
          <LayoutPublic heroActive={rest.heroActive}>
            <Component {...matchProps} />
          </LayoutPublic>
        )
      )}
    />
  );
};

export default PublicRoute;
