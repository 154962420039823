import React from 'react';
import { history } from '../../globals/history';

const BackButton = ({
  className = '',
}) => {
  return (
    <button
      type="button"
      onClick={() => history.goBack()}
      className={`c-back-button ${className}`}
    >
      <i className="fas fa-arrow-left c-back-button__icon" />
    </button>
  );
};

export default BackButton;
