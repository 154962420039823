import React from 'react';
import { Formik } from 'formik';
import { Spinner } from '@acpaas-ui/react-components';
import { map } from 'lodash-es';
import DatePicker from '../../../../../components/shared/DatePicker';
import { Dropdown } from '../../../../../components/admin/Dropdown';
import deepCopy from '../../../../../functions/deepCopy';

const TableFilter = ({
  showFilter,
  loading,
  locations,
  initialValues,

  setLoading,
  resetActivities,
  handleFilterForm,
}) => {
  return (
    showFilter && (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          setLoading(true);
          await resetActivities();
          await handleFilterForm(deepCopy(values));
          setLoading(false);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="c-filter"
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit();
              }
            }}
          >
            <header className="c-filter__header">
              <h3>Filter</h3>
            </header>

            <div className="c-filter__content">
              <div className="c-filter__input c-filter__input--big">
                <input
                  type="text"
                  name="search"
                  onChange={handleChange}
                  value={values.search}
                  className="c-input"
                  placeholder="Zoeken..."
                />
                <i className="fas fa-search c-filter__input-icon" />
              </div>

              <div className="c-filter__divider u-hide@below-viewport-9" />

              <label className="c-filter__label c-filter__label--start">Start van</label>
              <div className="c-filter__input">
                <DatePicker
                  name="start"
                  maxDate={values.end}
                  onChange={e => {
                    handleChange(e);
                    handleSubmit();
                  }}
                  value={values.start}
                />
              </div>

              <label className="c-filter__label c-filter__label--end">tot</label>
              <div className="c-filter__input">
                <DatePicker
                  name="end"
                  value={values.end}
                  minDate={values.start}
                  onChange={e => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
              </div>

              <div className="c-filter__divider u-hide@below-viewport-9" />

              <div className="c-filter__input c-filter__input--big">
                <Dropdown
                  name="location"
                  onChange={e => {
                    handleChange(e);
                    handleSubmit();
                  }}
                  value={values.location}
                  options={map(locations, (loc, i) => ({ key: i, value: loc.id, label: loc.name }))}
                  placeholder="Locatie"
                />
              </div>

              <button
                className="c-filter__submit"
                type="submit"
                disabled={isSubmitting}
              >
                {loading ? <Spinner /> : 'Filter'}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
    ));
};

export default TableFilter;
