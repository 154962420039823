import React from 'react';
import { Spinner } from '@acpaas-ui/react-components';
import { noop } from 'lodash-es';
import { Link } from 'react-router-dom';

const Button = ({
  className = '',
  text,
  iconBefore = null,
  iconAfter = null,
  type = 'button',
  url,
  newTab,
  onClick,
  children,
  icon,
  disabled,
  outline = false,
  loading,
}) => {
  if (outline) {
    className += ' c-button--with-outline';
  }
  return (
    type === 'link'
      ? (
        <Link
          to={url}
          className={`c-button ${disabled ? 'is-disabled' : ''} ${className}`}
          target={newTab ? '_blank' : '_self'}
        >
          {iconBefore && (
            <span className="c-button__icon c-button__icon--before">
              {children}
            </span>
          )}

          <span className={`${icon ? 'c-button__icon' : 'c-button__text'}`}>
            {icon ? children : text}
          </span>

          {iconAfter && (
            <span className="c-button__icon c-button__icon--after">
              {children}
            </span>
          )}
        </Link>
      )
      : type === 'submit'
        ? (
          <button
            type="submit"
            className={`c-button ${disabled ? 'is-disabled' : ''} ${className}`}
            onClick={!loading ? onClick : noop}
          >
            {iconBefore && !loading && (
              <span className="c-button__icon c-button__icon--before">
                {children}
              </span>
            )}

            {loading && (
              <span className="c-button__icon c-button__icon--before u-text-white">
                <Spinner />
              </span>
            )}

            <span className={`${icon ? 'c-button__icon' : 'c-button__text'}`}>
              {icon ? children : text}
            </span>

            {iconAfter && (
              <span className="c-button__icon c-button__icon--after">
                {children}
              </span>
            )}
          </button>
        )
        : (
          <button
            type="button"
            className={`c-button ${disabled ? 'is-disabled' : ''} ${className}`}
            onClick={!loading ? onClick : noop}
          >
            {iconBefore && !loading && (
            <span className="c-button__icon c-button__icon--before">
                {children}
            </span>
            )}

            {loading && (
              <span className="c-button__icon c-button__icon--before u-text-white">
                <Spinner />
              </span>
            )}

            <span className={`${icon ? 'c-button__icon' : 'c-button__text'}`}>
              {icon ? children : text}
            </span>

            {iconAfter && (
            <span className="c-button__icon c-button__icon--after">
                {children}
            </span>
            )}
          </button>
        )
  );
};

export default Button;
