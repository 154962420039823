
import React, { Suspense } from 'react';

import { Spinner } from '@acpaas-ui/react-components';
import { ModalRoot } from '../components/ModalRoot';
import Header from '../components/admin/Header';

// =============================================================================
// :: Assets
// =============================================================================
import '../assets/scss/admin/admin.scss';

import Footer from '../components/shared/Footer';

// =============================================================================
// :: Layout
// =============================================================================

const LayoutAdmin = ({ children, noMatch }) => {
  return (
    <div className="s-admin">
      <div className="s-admin__content">
        <Header noMatch={noMatch} />
        <Suspense fallback={(
          <div className="row center-xs u-margin">
            <Spinner className="col-xs-12" size="large" />
          </div>
        )}
        >
          {children}
        </Suspense>
      </div>
      <Footer text={`© ${new Date().getFullYear()} Digipolis`} />
      <ModalRoot />
    </div>
  );
};

export default LayoutAdmin;
