import React from 'react';
import { Link } from 'react-router-dom';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import Button from '../Button';

export default () => {
  return (
    <LayoutAdmin>
      <div className="p-logout">
        <h3 className="u-margin-top-xs">
          Je bent succesvol uitgelogd.
        </h3>
        <Link className="u-margin-top" to="/admin">
          <Button
            className="u-margin-top-xs"
            outline
            text="Opnieuw inloggen"
          >
            Opnieuw inloggen
          </Button>
        </Link>
      </div>
    </LayoutAdmin>
  );
};
