import React from 'react';

import BackButton from './BackButton';
import webpuntLogo from '../../assets/images/brand/webpunt-logo.png';
import webpuntLogoWhite from '../../assets/images/brand/webpunt-logo-white.png';

const Hero = ({
  pageTitle,
  image,
  backButton,
  className,
}) => {
  return (
    <header>
      {backButton && <BackButton className="c-hero__back-button" />}

      <div className={`c-hero l-container ${className}`}>
        <div className="c-hero__content">
          <a href="https://www.webpunt.net">
            <img
              className="c-hero__logo u-hide@below-viewport-7"
              src={webpuntLogo}
              alt="Webpunt logo"
            />

            <div className="c-hero__logo--small u-hide@above-viewport-7">
              <img
                src={webpuntLogoWhite}
                alt="Webpunt logo small"
              />
            </div>
          </a>

          <div className="c-hero__title-wrapper">
            <h1 className="c-hero__title">
              { pageTitle }
            </h1>
          </div>
        </div>

        <div className="c-hero__image-wrapper u-hide@below-viewport-7">
          <div className="c-hero__image">
            <img
              src={image}
              alt="Hero visual"
            />
          </div>
        </div>
      </div>
    </header>

  );
};

export default Hero;
