const checkDepartmentIsOwn = (data) => {
  const { scope } = data.decodedJWT;
  return data.departmentName === scope;
};

const teacherRules = [
  'overview:view',
  'activity:view',
  'customer:view',
  'customer:edit',
  'attendance:edit',
  'registration:edit',
];

const employeeRules = [
  'customer:create',
  'applicant:create',
  'applicant:edit',
  'applicant:remove',
  'activity:edit',
  'activity:create',
  'registration:create',
  'settings:view',
];

const departmentAdminRules = [
  'activity:cancel',
  'location:edit',
  'location:create',
  'department:edit',
  'category:edit',
  'category:create',
  'category:remove',
  'teacher:create',
  'teacher:edit',
  'teacher:remove',
  'activity:delete',
  'audit:view',
];

const organisationAdminRules = [
  // organisation admin can always mutate registration/mutate, no need for dynamic checking
  'registration:mutate:own',
  'visit:mutate:own',
  'customer:delete',
  'department:create',
  'organisation:edit',
];

const domainAdminRules = [
  'organisation:create',
  'domain:edit',
];

const globalAdminRules = [
  'domain:create',
];

const rules = {
  teacher: {
    static: [
      ...teacherRules,
    ],
  },
  employee: {
    static: [
      ...teacherRules,
      ...employeeRules,
    ],
    dynamic: {
      'registration:mutate:own': checkDepartmentIsOwn,
      'visit:mutate:own': checkDepartmentIsOwn,
      'note:mutate:own': checkDepartmentIsOwn,
    },
  },
  'department-admin': {
    static: [
      ...teacherRules,
      ...employeeRules,
      ...departmentAdminRules,
    ],
    dynamic: {
      'registration:mutate:own': checkDepartmentIsOwn,
      'visit:mutate:own': checkDepartmentIsOwn,
      'note:mutate:own': checkDepartmentIsOwn,
    },
  },
  'organisation-admin': {
    static: [
      ...teacherRules,
      ...employeeRules,
      ...departmentAdminRules,
      ...organisationAdminRules,
    ],
  },
  'domain-admin': {
    static: [
      ...teacherRules,
      ...employeeRules,
      ...departmentAdminRules,
      ...organisationAdminRules,
      ...domainAdminRules,
    ],
  },
  'global-admin': {
    static: [
      ...teacherRules,
      ...employeeRules,
      ...departmentAdminRules,
      ...organisationAdminRules,
      ...domainAdminRules,
      ...globalAdminRules,
    ],
  },
};


export default rules;
