import React, { Component } from 'react';
import moment from 'moment';
import { withApollo } from 'react-apollo';
import { forEach, concat, reject, toUpper, find, isEmpty } from 'lodash-es';
import qs from 'qs';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import withDataConsumer from '../../../../contextApi/withDataConsumer';
import { fetchActivityList } from '../../../../api/graphql/activities';
import PublicActivitiesTemplate from './PublicActivitiesTemplate';
import { generateStringFilter, generateIntFilter, generateDateBetweenFilter } from '../../../../functions/filterGenerators';
import { fetchLocationList } from '../../../../api/graphql/locations';
import { convertToISOString } from '../../../../functions/DateTimeHelpers';

const tableHeaders = [{
  text: 'Opleiding',
  width: 6,
}, {
  text: 'Locatie',
  sortable: true,
  width: 2,
}, {
  text: 'Startdatum',
  centered: true,
  width: 1.5,
}, {
  text: 'Einddatum',
  centered: true,
  width: 1.5,
}, {
  text: 'Bezetting',
  centered: true,
  sortable: false,
  width: 1.2,
}];

class ActivitiesPage extends Component {
  state = {
    loadingLocations: true,
    loadingActivities: true,
    showFilter: true,
    tableHeaders,
    tableData: [],
    locations: [],
    page: 1,
    pageSize: 25,
    orderBy: { start: 'ASC' },
    initialFilterValues: {
      search: '',
      start: '',
      end: '',
      location: '',
    },
  }

  componentDidMount() {
    try {
      if (!isEmpty(this.props.location.search)) {
        this.getUrlQueries(this.props.location.search);
      } else {
        this.fetchActivities();
      }
      this.fetchLocations();
    } catch (e) {
      this.setState({ error: e, loading: false, loadingActivities: false });
    }
  }

  getUrlQueries = (query) => {
    const formValues = qs.parse(query, { ignoreQueryPrefix: true });
    formValues.start = formValues.start ? new Date(formValues.start) : '';
    formValues.end = formValues.end ? new Date(formValues.end) : '';
    this.setState({ initialFilterValues: formValues }, () => this.handleFilterForm(formValues));
  }

  setUrlQueries = (query) => {
    window.history.pushState('', '', query);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  toggleFilter = () => {
    this.setState((prevState) => {
      return {
        showFilter: !prevState.showFilter,
      };
    });
  }

  fetchLocations = async () => {
    try {
      const { data: { locations } } = await this.props.client.query({
        query: fetchLocationList,
        variables: {
          orderBy: { name: 'ASC' },
          where: {
            classrooms: {
              some: {
                lessons: { some: { start: { gt: convertToISOString(moment()) } } },
              },
            },
          },
          take: 1000,
        },
      });
      this.setState({ locations, loadingLocations: false });
    } catch (e) {
      this.setState({ error: e, loadingLocations: false });
    }
  }

  fetchActivities = async (where = { AND: [] }) => {
    // only fetch published activities
    where.AND.push({ published: { equals: true } });
    where.AND.push({ canceled: { equals: false } });
    where.AND.push({ deleted: { equals: false } });
    if (!find(where.AND, o => 'end' in o)) {
      where.AND.push({ start: { gt: convertToISOString(moment()) } });
    }
    const { pageSize, activities = [], page, orderBy } = this.state;
    const { data: { activities: newActivities } } = await this.props.client.query({
      query: fetchActivityList,
      variables: {
        where,
        orderBy,
        take: pageSize,
        skip: (page - 1) * pageSize,
      },
      fetchPolicy: 'no-cache',
    });

    const combinedActivities = concat(activities, newActivities);
    const parsedActivities = this.parseActivities(combinedActivities);

    // Set table params;
    this.setState({
      activities: combinedActivities,
      tableData: parsedActivities,
      loadingActivities: false,
      loading: false,
    });
  }

  // :: Parse function
  parseActivities = (activities) => {
    const parsedActivities = [];
    forEach(activities, (activity) => {
      const parsedActivity = [];
      const {
        id: activityId,
        name,
        registrations,
        maxParticipants,
        locationName,
        start,
        end,
      } = activity;

      // Opleiding
      parsedActivity.push({
        value: name,
        type: 'link',
        href: `/public/activities/${activityId}`,
      });

      // Locatie
      parsedActivity.push({
        value: locationName,
        type: 'text',
      });

      // Startdatum
      parsedActivity.push({
        value: moment(start).format('l'),
        centered: true,
        type: 'text',
      });

      // Einddatum
      parsedActivity.push({
        value: moment(end).format('l'),
        centered: true,
        type: 'text',
      });

      const activeRegistrations = reject(registrations, ('canceled'));

      // Bezetting
      if (activeRegistrations.length !== activity.maxParticipants) {
        parsedActivity.push({
          value: `${activeRegistrations.length}/${maxParticipants}`,
          type: 'text',
          centered: true,
        });
      } else {
        parsedActivity.push({
          value: 'Volzet',
          centered: true,
          type: 'text',
          className: 'u-text-red',
        });
      }

      parsedActivities.push(parsedActivity);
    });

    return parsedActivities;
  }

  resetFilter = async () => {
    await this.setStateAsync({ activities: [], formValues: {} });
    await this.fetchCustomers(1);
  }

  getKeyByDataId = (id) => {
    switch (id) {
      case 'Opleiding':
        return 'name';
      case 'Locatie':
        return 'locationName';
      case 'Startdatum':
        return 'start';
      case 'Einddatum':
        return 'end';
      default:
        return '';
    }
  }

  handleSorting = async (data) => {
    const { id, sorting } = data;
    const key = this.getKeyByDataId(id);
    await this.setStateAsync({
      activities: [],
      orderBy: { [key]: toUpper(sorting) },
    });
    this.handlePageChange(1);
  }

  handleFilterForm = async (formValues) => {
    await this.setStateAsync({ formValues });
    formValues.start = formValues.start ? new Date(formValues.start) : '';
    formValues.end = formValues.end ? new Date(formValues.end) : '';
    this.setUrlQueries(`activities?${qs.stringify(formValues)}`);
    const {
      search, start, end, location,
    } = formValues;
    const where = { AND: [] };
    if (search) {
      where.AND.push({
        OR: [
          ...generateIntFilter('courseNumber', search),
          ...generateStringFilter('name', search),
          ...generateStringFilter('abbreviation', search),
          {
            lessons: {
              some: {
                classroom: {
                  location: {
                    OR: [
                      ...generateStringFilter('name', search),
                    ],
                  },
                },
              },
            },
          },
        ],
      });
    }
    if (location) {
      where.AND.push({
        lessons: {
          some: {
            classroom: {
              location: {
                id: { equals: location },
              },
            },
          },
        },
      });
    }
    if (end || start) {
      where.AND.push(...generateDateBetweenFilter(start, end));
    }

    await this.fetchActivities(where);
  }

  handlePageChange = async (page) => {
    const { formValues: { search, start, end, location } = {}, formValues } = this.state;
    await this.setStateAsync({ page });
    if (
      (search && search !== '')
      || (start && start !== '')
      || (end && end !== '')
      || (location && location !== '')
    ) {
      // fetch filtered next page
      await this.handleFilterForm(formValues);
    } else {
      // fetch normal next page (no filter values)
      await this.fetchActivities();
    }
  }

  resetActivities = async () => {
    await this.setStateAsync({ activities: [] });
  }

  // :: Render
  render() {
    return (
      <PublicActivitiesTemplate
        fetchPage={this.handlePageChange}
        toggleFilter={this.toggleFilter}
        handleFilterForm={this.handleFilterForm}
        handleSorting={this.handleSorting}
        resetActivities={this.resetActivities}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default withRouter(withApollo(withDataConsumer(ActivitiesPage)));
