
import React, { Component } from 'react';
import cookies from 'react-cookies';
import { Link } from 'react-router-dom';

import { UserMenu } from '@acpaas-ui/react-components';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import logo from '../../assets/images/brand/webpunt-logo-white.png';
import withDataConsumer from '../../contextApi/withDataConsumer';
import withModalFunctions from '../shared/_modals/withModalFunctions';
import CanUser from '../shared/CanUser';

const jwtDecode = require('jwt-decode');

class HeaderContainer extends Component {
  render() {
    const {
      noMatch,
      match: {
        path,
      },
    } = this.props;
    const jwt = cookies.load('jwt');
    const decodedJWT = jwt ? jwtDecode(jwt) : {};
    const loggedIn = cookies.load('Authorization');
    return (
      <header className={classNames(['c-header', { 'c-header__no-match': noMatch }])}>
        <div className="c-header__left">
          <Link to="/admin">
            <img src={logo} alt="webpunten-logo" className="c-header__logo" />
          </Link>
        </div>
        {!noMatch && (
          <>
            <div className="c-header__actions">
              <UserMenu
                user={{
                  firstName: decodedJWT.name || 'Onbekende gebruiker',
                  lastName: '',
                }}
                loggedIn={loggedIn}
                logoutUrl="/start-logout"
                loginUrl="/"
                flyoutSize="medium"
              />
              {loggedIn && (
                <CanUser
                  perform="settings:view"
                  yes={() => (
                    <Link
                      to="/admin/settings"
                      target={path === '/admin' ? '_blank' : '_self'}
                    >
                      <button type="button" className="c-header__settings">
                        <i className="fas fa-cog" />
                      </button>
                    </Link>
                  )}
                />
              )}
            </div>
          </>
        )}
      </header>
    );
  }
}

export default withModalFunctions(withDataConsumer(withRouter(HeaderContainer)));
