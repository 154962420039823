import React from 'react';
import { map, isEmpty, reduce, noop } from 'lodash-es';
import { Spinner } from '@acpaas-ui/react-components';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import WithTooltip from '../_hoc/WithTooltip';
import c from '../../../functions/c';

const TableBody = ({
  items,
  headers,
  loading,

  handleRemove,
  handleStop,
  handleEdit,
  handleCancel,
}) => {
  const handleRemoveItem = (e) => {
    handleRemove(e.target.dataset.id);
  };

  const handleStopItem = (e) => {
    handleStop(e.target.dataset.id);
  };

  const handleEditItem = (e) => {
    handleEdit(e.target.dataset.id);
  };

  const handleCancelItem = (e) => {
    handleCancel(e.target.dataset.id);
  };

  const actionSwitch = (action) => {
    switch (action.type) {
      case 'remove':
        return (
          <WithTooltip
            disabled={!action.tooltip}
            tooltipText={action.tooltip}
          >
            <i
              className={classnames('fas fa-times u-text-red ', {
                'u-text-grey': action.disabled,
                'c-table__body__icon--selectable': !action.disabled,
                [action.className]: action.className,
              })}
              key={action.type}
              onClick={!action.disabled ? handleRemoveItem : noop}
              data-id={action.id}
            />
          </WithTooltip>
        );

      case 'edit':
        return (
          <WithTooltip
            disabled={!action.tooltip}
            tooltipText={action.tooltip}
          >
            <i
              className={classnames('fas fa-pencil-alt u-text-blue ', {
                'u-text-grey': action.disabled,
                'c-table__body__icon--selectable': !action.disabled,
                [action.className]: action.className,
              })}
              key={action.type}
              onClick={!action.disabled ? handleEditItem : noop}
              data-id={action.id}
            />
          </WithTooltip>
        );

      case 'stop':
        return (
          <WithTooltip
            disabled={!action.tooltip}
            tooltipText={action.tooltip}
          >
            <i
              className={classnames('fas fa-square u-text-red ', {
                'u-text-grey': action.disabled,
                'c-table__body__icon--selectable': !action.disabled,
                [action.className]: action.className,
              })}
              key={action.type}
              onClick={!action.disabled ? handleStopItem : noop}
              data-id={action.id}
            />
          </WithTooltip>
        );

      case 'cancel':
        return (
          <WithTooltip
            disabled={!action.tooltip}
            tooltipText={action.tooltip}
          >
            <i
              className={classnames('fas fa-ban u-text-orange ', {
                'u-text-grey': action.disabled,
                'c-table__body__icon--selectable': !action.disabled,
                [action.className]: action.className,
              })}
              key={action.type}
              onClick={!action.disabled ? handleCancelItem : noop}
              data-id={action.id}
            />
          </WithTooltip>
        );

      default:
        return (action.href ? (
          !action.disabled && (
            <WithTooltip
              disabled={!action.tooltip}
              tooltipText={action.tooltip}
            >
              <Link
                className={classnames('c-link u-text-selectable', {
                  'u-text-grey': action.disabled,
                  'c-table__body__icon--selectable': !action.disabled,
                  [action.className]: action.className,
                })}
                key={action.type}
                to={action.href}
                target={action.newTab ? '_blank' : '_self'}
              >
                <i className={`fa fa-${action.icon} u-no-margin`} />
              </Link>
            </WithTooltip>
          )
        ) : (
          <WithTooltip
            disabled={!action.tooltip}
            tooltipText={action.tooltip}
          >
            <button
              key={action.type}
              className={classnames('c-link u-text-selectable', {
                'u-text-grey': action.disabled,
                'u-text-blue': !action.disabled,
                'c-table__body__icon--selectable': !action.disabled,
                [action.className]: action.className,
              })}
              onClick={!action.disabled ? () => action.onClick(action.id) : noop}
              data-id={action.id}
              type="button"
              disabled={action.disabled}
            >
              {action.value}
            </button>
          </WithTooltip>
        )
        );
    }
  };

  const renderBody = (item, rowIndex) => {
    return (
      <tr key={item.id || rowIndex}>
        {map(item, ({
          type,
          href,
          value,
          centered,
          sticky,
          buttons,
          className,
          newTab,
          tooltip,
        }, index) => {
          index += 1;
          rowIndex += 1;
          const cn = c(className, {
            'c-table__cell--centered': centered,
            'c-table__cell--sticky': sticky,
          });
          switch (type) {
            case 'link':
              return (
                <td
                  key={rowIndex * index}
                  className={cn}
                >
                  <WithTooltip
                    disabled={!tooltip}
                    tooltipText={tooltip}
                  >
                    <Link className={c('c-link', cn)} to={href} target={newTab ? '_blank' : '_self'}>
                      {value}
                    </Link>
                  </WithTooltip>
                </td>
              );

            case 'bullet':
              if (value === true) {
                return (
                  <td key={rowIndex * index} className={cn}>
                    <WithTooltip
                      disabled={!tooltip}
                      tooltipText={tooltip}
                    >
                      <span className="c-bullet" />
                    </WithTooltip>
                  </td>
                );
              }
              return (
                <td key={rowIndex * index} className={cn}>
                  <WithTooltip
                    disabled={!tooltip}
                    tooltipText={tooltip}
                  >
                    <span className="c-bullet c-bullet--inactive" />
                  </WithTooltip>
                </td>
              );

            case 'boolean':
              return (
                <td key={rowIndex * index} className={cn}>
                  <WithTooltip
                    disabled={!tooltip}
                    tooltipText={tooltip}
                  >
                    <i className={`fas ${value ? 'fa-check c-icon--green' : 'fa-times c-icon--red'} `} />
                  </WithTooltip>
                </td>
              );

            case 'actions':
              return map(buttons, (action, actionIndex) => (
                <td key={(rowIndex * index) + actionIndex} className={c('c-table__cell--actions', cn)}>
                  {actionSwitch(action, actionIndex)}
                </td>
              ));

            default:
              return (
                <td key={rowIndex * index} className={cn}>
                  <WithTooltip
                    disabled={!tooltip}
                    tooltipText={tooltip}
                  >
                    {value}
                  </WithTooltip>
                </td>
              );
          }
        })}
      </tr>
    );
  };

  const fullWidth = reduce(headers, (result, { colSpan = 1 }) => result += colSpan, 0);
  return (
    <tbody className="c-table__body">
      {loading ? (
        <tr>
          <td className="c-table__cell--centered" colSpan={fullWidth}><Spinner size="large" /></td>
        </tr>
      ) : !isEmpty(items) ? (
        map(items, renderBody)
      ) : (
        <tr>
          <td colSpan={fullWidth}>
            Geen items om te tonen
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
