import gql from 'graphql-tag';

export const fetchLocationList = gql`
  query fetchLocationList($where: LocationWhereInput!, $skip: Int, $take: Int, $orderBy: LocationOrderByInput) {
    locations(
      where: $where 
      skip: $skip
      take: $take
      orderBy: $orderBy
    ) {
      id
      name
      visitsEnabled
      visitComputers
      email
      address {
        street
        number
        bus
        zip
        city
      }
    }
    locationCount(where: $where)
  }`;

export const fetchLocationsByWhereInput = gql`
query fetchLocationsByWhereInput($where: LocationWhereInput!, $skip: Int, $take: Int) {
  locations(
    where: $where,
    skip: $skip, 
    take: $take,
    orderBy: { name: ASC }
  ) {
    id
    name
  }
}`;

export const fetchLocationListWithClassrooms = gql`
query fetchLocationsByWhereInput($where: LocationWhereInput!, $take: Int) {
  locations(
    where: $where,
    take: $take,
    orderBy: { name: ASC }
  ) {
    id
    name
    classrooms {
      id
      name
    }
  }
}`;

export const createOneLocation = gql`
  mutation ($locationCreateInput: LocationCreateInput!) {
    createOneLocation(data: $locationCreateInput) {
      id
    }
  }
`;

export const updateOneLocation = gql`
  mutation ($locationId: String!, $locationUpdateInput: LocationUpdateInput!) {
    updateOneLocation(data: $locationUpdateInput, where: {id: $locationId} ) {
      id
    }
  }
`;

export const deleteOneLocationById = gql`
  mutation ($locationId: String!) {
    deleteOneLocation(where: {id: $locationId} ) {
      id
    }
  }
`;

export const fetchOneLocationById = gql`
query fetchOneLocationById($locationId: String!) {
  location(where: { id: $locationId }) {
      id
      name
      description
      email
      telephone
      website
      visitComputers
      visitsEnabled
      department {
        id
      }
      address {
        street
        number
        bus
        zip
        city
        country
      }
      contact {
        firstname
        lastname
        email
        mobile
      }
      openingSlots {
        id
        days
        start
        end
        note
      }
    }
  }
`;
