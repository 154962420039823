import React, { Component } from 'react';
import { head, last, forEach, find } from 'lodash-es';
import { withApollo } from 'react-apollo';
import withDataConsumer from '../../../../contextApi/withDataConsumer';
import ActivityDetailTemplate from './PublicActivityDetailTemplate';
import { fetchOneActivityById } from '../../../../api/graphql/activities';
import { isDateInPast, parseHoursToString, parseDayToText, parseToReadableDate } from '../../../../functions/DateTimeHelpers';

class ActivityDetailPage extends Component {
  state = {
    loadingActivity: true,
  }

  componentDidMount() {
    try {
      this.fetchActivityDetail();
    } catch (e) {
      this.setState({ error: e, loadingActivity: false });
    }
  }

  fetchActivityDetail = async () => {
    const { match: { params: { activityId } }, client } = this.props;

    const { data: { activity, registrationCount } } = await client.query({
      query: fetchOneActivityById,
      variables: { activityId },
    });

    const parsedActivity = this.parseActivity(activity, registrationCount);

    // Set table params;
    await this.setState({
      activity: parsedActivity,
      loadingActivity: false,
    });
  }

  // :: Parse function
  parseActivity = (activity, registrationCount) => {
    let firstLesson = head(activity.lessons);
    const lastLesson = last(activity.lessons);
    if (!firstLesson) {
      firstLesson = { classroom: 'N/A' };
    }

    const { location, location: { address } = {} } = firstLesson.classroom;

    // general
    const parsedActivity = {
      name: activity.name,
      subscribeDescription: activity.department.subscribeDescription,
      content: activity.content.replace(/<\/?[^>]+>/ig, ' ').replace(/&lsquo;/g, '').replace(/&rsquo;/g, ''),
      note: activity.note,
      price: activity.price,
      priceType: activity.priceType,
      priceTypeNA: activity.priceTypeNA,
      priceNA: activity.priceNA,
      publicNote: activity.publicNote,
      occupation: `${registrationCount}/${activity.maxParticipants}`,
      occupationPercent: registrationCount / activity.maxParticipants,
      start: firstLesson.start ? parseToReadableDate(firstLesson.start) : 'N/A',
      end: lastLesson ? parseToReadableDate(lastLesson.start) : 'N/A',
    };

    if (location) {
      parsedActivity.locationName = location.name;
      parsedActivity.locationContact = location.telephone || location.mobile || '';
      parsedActivity.locationAddress = `${address.street} ${address.number} ${address.zip} ${address.city}`;
    } else {
      parsedActivity.locationName = 'N/A';
      parsedActivity.locationContact = 'N/A';
      parsedActivity.locationAddress = 'N/A';
    }

    // lessons
    parsedActivity.lessons = [];
    forEach(activity.lessons, ({ start, end }) => {
      const parsedLesson = {};

      parsedLesson.day = parseDayToText(start);
      parsedLesson.hours = parseHoursToString(start, end);

      const inPast = isDateInPast(start);
      parsedLesson.past = inPast;
      // if there no active lesson set yet, set active to true since this
      // is the first lesson that will be organised
      if (!find(parsedActivity.lessons, 'active') && !inPast) {
        parsedLesson.active = true;
      }

      parsedActivity.lessons.push(parsedLesson);
    });

    return parsedActivity;
  }

  // :: Render
  render() {
    return (
      <ActivityDetailTemplate
        switchMenuType={this.switchMenuType}
        {...this.props}
        {...this.state}
      />
    );
  }
}

export default withApollo(withDataConsumer(ActivityDetailPage));
