import React from 'react';
import classnames from 'classnames';

const MoreButton = ({
  className = '',
  text = 'Bekijk meer',
  onClick,
  loading,
}) => {
  return (
    <button
      type="button"
      className={`c-more-button ${className}`}
      onClick={onClick}
    >
      <div className="c-more-button__icon-wrapper">
        <i className={classnames('fas fa-plus c-more-button__icon', { 'fa-spin': loading })} />
      </div>

      <span className="c-more-button__text">
        {text}
      </span>
    </button>
  );
};

export default MoreButton;
