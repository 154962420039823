import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import { find } from 'lodash-es';

export const Dropdown = ({
  name,
  label,
  options,
  required,
  onChange,
  value,
  disabled,
  loading,
  clearable = true,
  ...props
}) => {
  const v = find(options, { value });
  return (
    <div className={classnames(['a-input', 'c-dropdown', { 'is-required': required }])}>
      {label && <label className="a-input__label">{label}</label> }
      <Select
        isClearable={clearable}
        isDisabled={disabled}
        className="c-dropdown__select"
        options={options}
        onChange={option => {
          onChange({ target: { name, value: option ? option.value : undefined } });
        }}
        isLoading={loading}
        value={v || null}
        {...props}
      />
    </div>
  );
};
