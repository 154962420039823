import React from 'react';

const Info = ({
  className = '',
  iconclassName = '',
  children,
  icon,
}) => {
  return (
    <div
      className={`c-info ${className}`}
    >
      <span
        className={`c-icon ${iconclassName}`}
      >
        {icon}
      </span>

      <p className="c-info__content">
        {children}
      </p>
    </div>
  );
};

export default Info;
