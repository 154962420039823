import React from 'react';

export const GlobalContext = React.createContext({ dataStore: {} });

export default class Provider extends React.Component {
  state = {
    dataStore: {
      authToken: null,
      selectedLocation: {},
    },
  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  handleDataStoreChange = async dataStore => {
    await this.setStateAsync({ dataStore });
  }

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          handleDataStoreChange: this.handleDataStoreChange,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
