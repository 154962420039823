import React from 'react';
import { map, upperFirst } from 'lodash-es';

import Info from './Info';

const Progress = ({
  className = '',
  lessons = [],
  columns = 2,
}) => {
  const splitLesson = Math.ceil(lessons.length / columns);

  return (
    <ul
      className={`c-progress ${className}`}
      style={{ columnCount: `${columns}`, MozColumnCount: `${columns}`, WebkitColumnCount: `${columns}` }}
    >
      {map(lessons, (lesson, index) => {
        return (
          <li
            className={`c-progress__item ${(index + 1) % splitLesson === 0 ? 'c-progress__item--split-end' : ''}`}
            key={index}
          >
            <Info
              className={`${lesson.past ? 'c-info--is-disabled' : ''} ${lesson.active ? 'c-info--is-active' : ''}`}
              iconclassName="c-progress__icon"
              icon={<i className="fas fa-chalkboard-teacher" />}
            >
              <span>
                <b>{index + 1} - {upperFirst(lesson.day)}</b><br />{lesson.hours}
              </span>
            </Info>
          </li>
        );
      })}
    </ul>
  );
};

export default Progress;
