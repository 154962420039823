import React, { useState } from 'react';
import { map, isEmpty } from 'lodash-es';

import { Spinner } from '@acpaas-ui/react-components';
import Hero from '../../../../components/public/Hero';
import Table from '../../../../components/shared/Table';
import CardList from '../../../../components/public/CardList';
import Card from '../../../../components/public/Card';
import TableFilter from './partials/PublicActivityTableFilter';

import image from '../../../../assets/images/image_home.jpg';
import withLoadingAndError from '../../../../components/shared/_hoc/withLoadingAndError';
import MoreButton from '../../../../components/public/MoreButton';

const ActivitiesTemplate = ({
  tableHeaders,
  tableData,
  loadingActivities,
  loadingLocations,
  page,
  locations,
  initialFilterValues,

  fetchPage,
  resetActivities,
  showFilter,
  // toggleFilter,
  handleFilterForm,
  handleSorting,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="p-educations">
      {/* TODO: enable with tags */}
      <Hero
        pageTitle="Alle opleidingen"
        image={image}
      />

      <section className="l-section l-container">
        <div className="l-section__header l-section__header--space">
          <div className="l-section__title">
            <h2>Waar en wanneer?</h2>
          </div>
          {/* <div className="l-section__actions">
            <Button
              className="c-button--with-outline"
              type="button"
              text="Filteren"
              onClick={toggleFilter}
              iconBefore
            >
              <i className="fas fa-filter" />
            </Button>
          </div> */}
        </div>

        <div className="l-section__content">
          <Table
            headers={tableHeaders}
            body={tableData}
            className="u-hide@below-viewport-9"
            loading={loadingActivities}
            handleSorting={handleSorting}
          >
            <TableFilter
              locations={locations}
              loadingLocations={loadingLocations}
              setLoading={setLoading}
              loading={loading}
              resetActivities={resetActivities}
              showFilter={showFilter}
              handleFilterForm={handleFilterForm}
              initialValues={initialFilterValues}
            />
          </Table>
          {!isEmpty(tableData) ? (
            <CardList className="u-hide@above-viewport-9">
              {map(tableData, (item, index) => {
                return <Card key={index} data={item} headers={tableHeaders} />;
              })}
            </CardList>
          ) : (
            !loadingActivities ? (
              <Card className="u-margin-bottom u-hide@above-viewport-9" disabled data={[{ value: 'Geen activiteiten gevonden' }]} />
            ) : <Spinner className="u-hide@above-viewport-9" />
          )}
          <MoreButton
            onClick={async () => {
              setLoading(true);
              await fetchPage(page + 1);
              setLoading(false);
            }}
            loading={loading}
          />
        </div>
      </section>
    </div>
  );
};

export default withLoadingAndError(ActivitiesTemplate);
