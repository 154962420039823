import React from 'react';
import { Tooltip } from 'react-tippy';

export default ({ children, tooltipText, disabled, position = 'top' }) => {
  return (
    <Tooltip
      disabled={disabled}
      title={tooltipText}
      position={position}
      trigger="mouseenter"
      arrow
      distance={12}
    >
      {children}
    </Tooltip>
  );
};
