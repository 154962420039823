export default {
  get(request, endpoint, options = {}) {
    return request(endpoint, options, 'get');
  },
  post(request, endpoint, options = {}) {
    return request(endpoint, options, 'post');
  },
  put(request, endpoint, options = {}) {
    return request(endpoint, options, 'put');
  },
  delete(request, endpoint, options = {}) {
    return request(endpoint, options, 'delete');
  },
  patch(request, endpoint, options = {}) {
    return request(endpoint, options, 'patch');
  },
  // some requests have a predefined method (oauth/fileupload)
  customRequest(request, endpoint, options = {}) {
    return request(endpoint, options);
  },
};
