import React, { Component, Fragment } from 'react';
import qs from 'qs';
import cookie from 'react-cookies';
import { withRouter, Redirect } from 'react-router-dom';
import { Spinner, Alert, Button } from '@acpaas-ui/react-components';
import { replace } from 'lodash-es';
import withDataConsumer from '../../../contextApi/withDataConsumer';
import axiosWrapper from '../../../api/shared/axiosWrapper';
import baseWebpRequest from '../../../api/shared/baseWebpRequest';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import { translateApiError } from '../../../functions/Translate';

class OAuth2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  async componentDidMount() {
    try {
      const { access_token: accessToken, expires_in: maxAge, state } = qs.parse(replace(this.props.location.hash, '#', ''), {
        ignoreQueryPrefix: true,
      });
      // Authorization token used in axios
      cookie.save('Authorization', accessToken, { maxAge });
      const response = await axiosWrapper.post(baseWebpRequest, '/auth', { proxy: true });
      cookie.save('jwt', response.token);

      this.setState({ redirect: state || '/admin' });
    } catch (e) {
      if (e.token) {
        // token used to logout "wrong" account
        // error object is nested in the response
        cookie.save('jwt', e.token);
        this.setState({ error: e.error });
      } else {
        this.setState({ error: e });
      }
    }
  }

  render() {
    const { error, redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <LayoutAdmin>
        <div className="FullScreenSpinner__Container">
          <div className="FullScreenSpinner">
            {error ? (
              <Alert type="danger">
                <h3 className="u-margin-top-xs">Er is iets misgegaan bij het inloggen</h3>
                <p className="u-margin-top-xs">{translateApiError(error.message) || translateApiError(error.title) || 'Ongekende fout.'}</p>
                <p className="u-margin-top-xs">{translateApiError(error.extraInfo) || 'Geen extra info.'}</p>
                <Button
                  className="u-margin-top-xs"
                  type="danger"
                  outline
                  onClick={() => {
                    if (cookie.load('jwt')) {
                      window.location.href = '/start-logout';
                    } else {
                      window.location.href = '/logout-success';
                    }
                  }}
                >
                  Probeer opnieuw
                </Button>
              </Alert>
            )
              : (
                <Fragment>
                  <Spinner size="large" />
                  <p className="FullScreenSpinner__Text">Checking OAuth code</p>
                </Fragment>
              )
          }
          </div>
        </div>
      </LayoutAdmin>
    );
  }
}

export default withRouter(withDataConsumer(OAuth2));
