import React, { Component } from 'react';
import { GlobalContext } from './GlobalContextProvider';

function withDataConsumer(WrappedComponent) {
  return class extends Component {
    render() {
      return (
        <GlobalContext.Consumer>
          {context => {
            return (
              <WrappedComponent {...this.props} context={context} />
            );
          }}
        </GlobalContext.Consumer>
      );
    }
  };
}

export default withDataConsumer;
