import React from 'react';
import { Spinner } from '@acpaas-ui/react-components';
import ErrorMessage from '../ErrorMessage';

const withLoadingAndError = (OriginalComponent) => class extends React.Component {
  render() {
    const { error, loading } = this.props;
    if (loading || error) {
      return (
        <div className="c-loader-container l-container">
          {loading && <Spinner size="large" />}
          {error && <ErrorMessage error={error} />}
        </div>
      );
    }
    return <OriginalComponent {...this.props} {...this.state} />;
  }
};
export default withLoadingAndError;

export const WithLoadingAndError = withLoadingAndError(({
  loading,
  error,
  children,
  className,
}) => {
  return (
    <div
      className={className}
      loading={loading}
      error={error}
    >
      {children}
    </div>
  );
});
