
import React, { Component } from 'react';
import { map, isEmpty, noop } from 'lodash-es';
import c from '../../../functions/c';

class TableHead extends Component {
  state = {
    activeSorting: {
      id: null,
      sorting: 'desc',
    },
  }

  handleSortColumn = (e) => {
    const dataId = e.target.dataset.id;
    let { activeSorting } = this.state;

    if (activeSorting.id === dataId && activeSorting.sorting === 'desc') {
      activeSorting = {
        id: dataId,
        sorting: 'asc',
      };
    } else {
      activeSorting = {
        id: dataId,
        sorting: 'desc',
      };
    }

    this.setState({ activeSorting }, () => this.props.handleSorting(activeSorting));
  };

    renderHeader = ({
      text,
      sortable = true,
      width = this.props.defaultWidth,
      centered = false,
      sticky = false,
      colSpan = 1,
    }, index) => {
      const { activeSorting } = this.state;
      const { items } = this.props;
      const widthPercent = width * 6.25;
      if (text === '') {
        return (
          <th colSpan={colSpan} width={`${widthPercent}%`} key={index} className={`${centered ? 'c-table__head__headercell--centered' : ''}`} />
        );
      }
      let icon = 'fa-sort u-text-blue';
      if (text === activeSorting.id) {
        if (activeSorting.sorting === 'desc') {
          icon = 'fa-sort-down u-text-blue';
        } else {
          icon = 'fa-sort-up u-text-blue';
        }
      }
      const cn = c({
        'c-table__head__headercell--sortable': sortable,
        'c-table__head__headercell--centered': centered,
        'c-table__head__headercell--sticky': sticky,
      });
      return (
        <th
          colSpan={colSpan}
          width={`${widthPercent}%`}
          key={index}
          onClick={sortable ? this.handleSortColumn : noop}
          data-id={text}
          className={cn}
        >
          {text}
          {sortable && !isEmpty(items) && <i onClick={sortable && this.handleSortColumn} data-id={text} className={`fas ${icon} c-table__head__sort`} />}
        </th>
      );
    };

    render() {
      return (
        <thead className="c-table__head">
          <tr>
            {map(this.props.headers, this.renderHeader)}
          </tr>
        </thead>
      );
    }
}

export default TableHead;
