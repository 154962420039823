import React from 'react';
import c from '../../../functions/c';

import TableBody from './TableBody';
import TableHead from './TableHead';

const Table = ({
  headers,
  body,
  children,
  activeSorting,
  loading,
  className,
  wrapperClassName,

  handleSorting,
  handleRemove,
  handleStop,
  handleEdit,
  handleCancel,
}) => {
  return (
    <div className={c('c-table__wrapper', wrapperClassName)}>
      {children}

      <table className={`c-table ${className}`}>
        <TableHead
          headers={headers}
          items={body}
          defaultWidth={16 / headers.length}
          activeSorting={activeSorting}
          handleSorting={handleSorting}
        />

        <TableBody
          headers={headers}
          items={body}
          loading={loading}
          handleRemove={handleRemove}
          handleStop={handleStop}
          handleEdit={handleEdit}
          handleCancel={handleCancel}
        />
      </table>
    </div>
  );
};

export default Table;
