import { includes, constant } from 'lodash-es';
import cookie from 'react-cookies';
import rules from './aclRules';

const jwtDecode = require('jwt-decode');

export const check = (action, data) => {
  const jwt = cookie.load('jwt');
  const decodedJWT = jwt ? jwtDecode(jwt) : {};
  const permissions = rules[decodedJWT.role];

  // role is not present in the rules
  if (!permissions) return false;

  const staticPermissions = permissions.static;

  // static rule provided for action
  if (staticPermissions && includes(staticPermissions, action)) return true;

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    // dynamic rule not provided for action
    if (!permissionCondition) return false;

    return permissionCondition({ ...data, decodedJWT });
  }
  // neither static or dynamic rule that allows action
  return false;
};

const CanUser = props => (check(props.perform, props.data)
  ? props.yes()
  : props.no());

CanUser.defaultProps = {
  yes: constant(null),
  no: constant(null),
};

export default CanUser;
