import React from 'react';

const Contact = ({
  className = '',
  telephone,
  place,
  address,
}) => {
  return (
    <div
      className={`c-contact ${className}`}
    >
      <p className="c-contact__item">
        <i className="fas fa-location-arrow c-contact__icon" />

        <span className="c-contact__content">
          <span className="c-contact__location-place">
            {place}
          </span>

          <span className="c-contact__location-adress">
            {address}
          </span>
        </span>
      </p>

      <p className="c-contact__item">
        <i className="fas fa-phone c-contact__icon" />

        <a
          href={`tel:${telephone}`}
          className="c-contact__content c-contact__telephone"
        >
          {telephone}
        </a>
      </p>
    </div>
  );
};

export default Contact;
