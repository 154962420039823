import React from 'react';
import classnames from 'classnames';

import { ModalRoot } from '../components/ModalRoot';

import '../assets/scss/public/public.scss';
import Footer from '../components/shared/Footer';

const LayoutPublic = ({ children, heroActive }) => {
  return (
    <div className={classnames('s-public', { 's-public--hero': heroActive })}>
      <div className="s-public__content">
        {children}
      </div>

      <Footer
        // hacking the Leap Forward footer with some recoloring to make it look empty
        className="c-footer__no-text"
        text="-"
      />

      <ModalRoot />
    </div>
  );
};

export default LayoutPublic;
