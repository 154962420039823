import axios from 'axios';
import cookie from 'react-cookies';
import { merge, replace } from 'lodash-es';

export class WebpApiConfig {
  static API_VERSION = '';
}

export default (endpoint, { headers = {}, body, ...options }, method) => {
  let allHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookie.load('Authorization')}`,
  };
  allHeaders = merge(allHeaders, headers);
  const { REACT_APP_URL_PROXY: proxy } = window._env_;
  const url = options.proxy ? `${proxy}${endpoint}` : `${replace(proxy, '/proxy', '')}${endpoint}`;
  return axios(url, {
    ...options,
    headers: allHeaders,
    timeout: options.timeout ? options.timeout : 10000,
    data: body ? JSON.stringify(body) : undefined,
    method,
  }).then(response => {
    return response.data;
  }).catch(error => {
    if (error.response) {
      throw error.response.data;
    } else {
      throw error;
    }
  });
};
