import React from 'react';
import { map } from 'lodash-es';

const CardList = ({
  className = '',
  children,
}) => {
  return (
    children && (
      <ul className={`c-card-list ${className}`}>
        {map(children, (child, index) => {
          return (
            <li
              key={index}
              className="c-card-list__item"
            >
              {React.cloneElement(child)}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default CardList;
