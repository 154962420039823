import React from 'react';
import ReactDOM from 'react-dom';
import cookies from 'react-cookies';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { HttpLink } from 'apollo-boost';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import GlobalContextProvider from './contextApi/GlobalContextProvider';
import { ModalProvider } from './contextApi/ModalProvider';
import { history } from './globals/history';

const client = new ApolloClient({
  link: new HttpLink(),
  uri: `${window._env_.REACT_APP_URL_PROXY}/graphql`,
  request: (operation) => {
    operation.setContext({
      headers: {
        // eslint-disable-next-line max-len
        // authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwicm9sZSI6ImRvbWFpbi1hZG1pbiIsInNjb3BlIjoiV2VicHVudGVuIn0.vZaxZF4ZXEGYS55fieE0NMCl7oSoKicZdCV20TbM53Q',
        authorization: `Bearer ${cookies.load('jwt')}`,
        SameSite: 'None',
        Secure: true,
      },
    });
  },
});

ReactDOM.render(
  <Router history={history}>
    <ModalProvider>
      <GlobalContextProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </GlobalContextProvider>
    </ModalProvider>
  </Router>,
  document.getElementById('root'),
);

serviceWorker.register();
