import React from 'react';
import Button from './Button';

const Footer = ({
  text,
  className = '',
}) => {
  return (
    <footer
      className={`c-footer ${className}`}
    >
      <div className="c-footer__content">
        <p className="c-footer__text">
          { text }
        </p>

        <Button
          className="c-footer__button"
          onClick={() => window.scrollTo(0, 0)}
          icon
        >
          <i className="fas fa-arrow-up c-footer__button-icon" />
        </Button>
      </div>
    </footer>
  );
};

export default Footer;
