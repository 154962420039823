import React, { useState } from 'react';
import { noop } from 'lodash-es';
import InfoList from './InfoList';
import Info from './Info';

const Card = ({
  className = '',
  data,
  disabled,
  headers,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section
      className={`c-card ${className} ${isOpen ? 'c-card--is-open' : ''} ${disabled ? 'c-card--disabled' : ''}`}
    >
      <header
        onClick={() => (!disabled ? setOpen(!isOpen) : noop)}
        className="c-card__header"
      >
        <h3 className="c-card__title">{data[0].value}</h3>

        {!disabled && <i className="fas fa-angle-right c-card__icon" /> }
      </header>

      {isOpen && (
        <div className="c-card__content-container">
          <div className="c-card__content">
            <Info
              className="c-card__content-item"
              iconclassName="c-card__content-icon"
              icon={<i className="fas fa-location-arrow" />}
            >
              <span>
                <b>{headers[1].text}</b><br />{data[1].value}
              </span>
            </Info>

            <InfoList
              className="c-card__content-item"
            >
              <Info
                iconclassName="c-card__content-icon"
                icon={<i className="fas fa-calendar-alt" />}
              >
                <b>{headers[2].text}</b><br />
                {data[2].value}
              </Info>

              <span><b>{headers[3].text}</b><br />
                {data[3].value}
              </span>
            </InfoList>

            {/* <Info
              className="c-card__content-item"
              iconclassName="c-card__content-icon"
              icon={<i className="fas fa-clock" />}
            >
              <b>{headers[4]}</b><br />
              {data[4].value}
            </Info> */}

            {/* <Info
              className="c-card__content-item"
              iconclassName="c-card__content-icon"
              icon={<i className="fas fa-tasks" />}
            >
              <b>{headers[4].text}</b><br />
              {data[4].value}
            </Info> */}

            <Info
              className="c-card__content-item"
              iconclassName="c-card__content-icon"
              icon={<i className="fas fa-users" />}
            >
              <b>{headers[4].text}</b><br />
              {data[4].value}
            </Info>

            <a
              href={data[0].href}
              className="c-card__link"
            >
              Meer info
            </a>
          </div>
        </div>
      )}
    </section>
  );
};

export default Card;
