
import React, { useState } from 'react';
import Button from '../shared/Button';

const Popup = ({
  text,
  className = '',
}) => {
  const [isHidden, setHidden] = useState(false);

  return (
    <div
      className={`c-popup l-container ${className} ${isHidden ? 'c-popup-hidden' : ''}`}
    >
      <p className="c-popup__text">
        { text }
      </p>

      <Button
        className="c-popup__icon"
        type="button"
        onClick={() => setHidden(true)}
        icon
      >
        <i className="fas fa-times" />
      </Button>
    </div>
  );
};

export default Popup;
