/* eslint-disable max-len */
import React from 'react';
import cookie from 'react-cookies';
import { Route, withRouter } from 'react-router-dom';
import LayoutAdmin from '../../layouts/LayoutAdmin';
import CanUser from '../../components/shared/CanUser';
import Unauthorized from '../../components/shared/_auth/Unauthorized';

const {
  REACT_APP_OAUTH2_URL_PORTAL: OAuth2Url,
  REACT_APP_OAUTH2_SERVICE: OAuth2Service,
  REACT_APP_OAUTH2_SCOPE: OAuth2Scope,
  REACT_APP_OAUTH2_CLIENT_ID: OAuth2ClientId,
  REACT_APP_OAUTH2_URL_CALLBACK: OAuth2Callback,
  REACT_APP_OAUTH2_HOST_URL: OAuth2HostUrl = `${window.location.protocol}//${window.location.host}`,
} = window._env_;

// eslint-disable-next-line no-unused-vars
const PrivateRoute = ({ location, ...props }) => ((cookie.load('Authorization') !== undefined)
  ? (
    <CanUser
      perform={props.acl}
      yes={() => {
        return (
          <Route {...props} />
        );
      }}
      no={() => <Route exact path={props.path} component={Unauthorized} />}
    />
  )
  : window.location.replace(`${OAuth2Url}?response_type=token&service=${OAuth2Service}&client_id=${OAuth2ClientId}&scope=${OAuth2Scope}&state=${location.pathname}&redirect_uri=${encodeURIComponent(`${OAuth2HostUrl}${OAuth2Callback}`)}&save_consent=true`)
);

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <PrivateRoute
      {...rest}
      render={matchProps => (
        <LayoutAdmin>
          <Component {...matchProps} />
        </LayoutAdmin>
      )}
    />
  );
};

export default withRouter(AdminRoute);
