import React, { Component } from 'react';
import { Alert, Button } from '@acpaas-ui/react-components';
import { Link } from 'react-router-dom';
import LayoutAdmin from '../../layouts/LayoutAdmin';

class NoMatch extends Component {
  render() {
    return (
      <LayoutAdmin noMatch>
        <div />
        <Alert
          className="NoMatch l-container"
          type="warning"
        >
          <h3 className="u-margin-bottom">De pagina die je zoekt kon niet gevonden worden</h3>
          <p>
            Neem contact op met een administrator als je
            zeker bent dat deze pagina bestaat.
          </p>
          <Link to="/">
            <Button className="NoMatch__Button" outline>Terug naar de homepage</Button>
          </Link>
        </Alert>
      </LayoutAdmin>
    );
  }
}

export default NoMatch;
