import moment from 'moment';
import { parseInt } from 'lodash-es';

export const parseToReadableDateTime = (dateTime) => {
  return moment(dateTime).format('DD-MM-YYYY HH:mm');
};

export const parseToReadableDate = (dateTime, format) => {
  return moment(dateTime).format(format || 'DD/MM/YYYY');
};

export const parseToReadableTime = (dateTime) => {
  return moment(dateTime).format('HH:mm');
};

export const getHoursFromDate = (dateTime) => {
  return moment(dateTime).format('HH');
};

export const getMinutesFromDate = (dateTime) => {
  return moment(dateTime).format('mm');
};

export const getNowFromMomentJs = () => {
  return moment().format();
};

export const nearestMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestPastMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const nearestFutureMinutes = (interval, someMoment) => {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

export const timeIsSameOrAfter = (startTime, endTime) => {
  // only take HH:mm, startdate can differ and be in the future
  const startTimeInMoment = moment(startTime).format('HH:mm');
  const endTimeInMoment = moment(endTime).format('HH:mm');
  return moment(endTimeInMoment, 'HH:mm').isSameOrAfter(moment(startTimeInMoment, 'HH:mm'));
};

export const convertToISOString = (value) => {
  return moment(value).toISOString();
};

export const getAgeFromBirthdate = (birthdate) => {
  const totalMonths = moment().diff(birthdate, 'months');
  const years = parseInt(totalMonths / 12);
  const months = totalMonths % 12;
  if (months !== 0) {
    return parseFloat(`${years}.${months}`);
  }
  return years;
};

export const convertMillisecondsToTimeStamp = (ms) => {
  if (ms < 0) {
    ms = 0;
  }
  return moment.utc(ms).format('HH:mm');
};

export const parseDayToText = (start) => {
  return moment(start).format('dddd L');
};

export const parseHoursToString = (start, end) => {
  const startingHour = moment(start).format('LT');
  const endingHour = moment(end).format('LT');
  return `Van ${startingHour} tot ${endingHour}`;
};

export const isDateInPast = (date) => {
  return moment().isAfter(moment(date));
};
