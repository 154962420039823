import React from 'react';
import { Link } from 'react-router-dom';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import Button from '../Button';

const Unauthorized = () => {
  return (
    <LayoutAdmin>
      <div className="p-unauthorized">
        <div className="l-container">
          <h1 className="p-unauthorized__emoji">
            (._.)?
          </h1>
          <h2 className="p-unauthorized__title u-margin-top">
            Je hebt niet voldoende rechten om deze pagina te bekijken
          </h2>
          <p className="p-unauthorized__info u-margin-top">
            Neem contact op met een administrator als je toegang nodig hebt tot deze pagina.
          </p>
          <Link className="u-margin-top" to="/admin">
            <Button className="u-margin-top" outline text="Terug naar het begin" />
          </Link>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default Unauthorized;
