import React, { useState, useEffect } from 'react';
import cookies from 'react-cookies';
import { Alert } from '@acpaas-ui/react-components';
import { Link } from 'react-router-dom';
import LayoutAdmin from '../../../layouts/LayoutAdmin';
import axiosWrapper from '../../../api/shared/axiosWrapper';
import baseWebpRequest from '../../../api/shared/baseWebpRequest';
import { translateApiError } from '../../../functions/Translate';
import Button from '../Button';

export default () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('selectedVisitLocation');
    axiosWrapper.get(baseWebpRequest, '/logout', { proxy: false }).then(res => {
      window.location.href = res.url;
      cookies.remove('Authorization');
      cookies.remove('jwt');
    }).catch(e => {
      setError(e);
      cookies.remove('Authorization');
      cookies.remove('jwt');
    });
  }, []);

  return (
    <LayoutAdmin>
      <div className="p-logout">
        {error ? (
          <>
            <Alert type="danger">
              <h3 className="u-margin-top-xs">Er is iets misgegaan bij het uitloggen</h3>
              <p className="u-margin-top-xs">{translateApiError(error.message) || translateApiError(error.title) || 'Ongekende fout.'}</p>
              <p className="u-margin-top-xs">{translateApiError(error.extraInfo) || 'Geen extra info.'}</p>
            </Alert>
            <Link className="u-margin-top" to="/admin">
              <Button
                className="u-margin-top-xs"
                outline
                text="Opnieuw inloggen"
              >
                Opnieuw inloggen
              </Button>
            </Link>
          </>
        ) : <p>Even geduld...</p>}
      </div>
    </LayoutAdmin>
  );
};
