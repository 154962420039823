import React from 'react';
import { Alert } from '@acpaas-ui/react-components';
import { map, isArray, isObject, values } from 'lodash-es';

const ListItem = ({ error = { } }) => {
  return <li className="u-margin-bottom-xs">{error.title || error.message || JSON.stringify(error)}</li>;
};

const ErrorMessage = ({ error, closable = true, message, validationError }) => {
  return (
    <>
      <div className="l-grid__col l-grid__col--12 u-margin-top-xs">
        <Alert className="u-margin-bottom-xs" type="danger" closable={closable}>
          <h3 className="u-margin-bottom">Er is iets foutgegaan</h3>
          <ul />
          {error.networkError ? (error.networkError.result ? (
            error.networkError.result.errors ? (
              map(error.networkError.result.errors, e => <li>{e.title || e.message}</li>)
            ) : (
              <li>
                {error.networkError.result.title
              || error.networkError.result.message
              || JSON.stringify(error.networkError.result)}
              </li>
            )
          ) : (
            <li className="u-margin-bottom-xs"> <i className="fa fa-warning u-margin-right" />{error.networkError.message}</li>
          )) : (
            <li className="u-margin-bottom-xs"> <i className="fa fa-warning u-margin-right" />{message || (error && (error.title || error.message))}</li>
          )}
          {validationError ? (
            isArray(error) && (
              map(error, e => {
                return isArray(e) ? (
                  map(e, err => (isObject(err) ? (
                    map(values(err), value => <ListItem error={value} />)
                  ) : (
                    <ListItem error={err} />
                  )))
                ) : (
                  isObject(e) ? (
                    map(values(e), value => <ListItem error={value} />)
                  ) : (
                    <ListItem error={e} />
                  )
                );
              })
            )
          ) : isArray(error) && (
            map(error, err => <ListItem error={err} />)
          )}
        </Alert>
      </div>
    </>
  );
};

export default ErrorMessage;
