import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Modal from 'react-modal';
import 'moment/locale/nl-be';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-tippy/dist/tippy.css';
import NoMatch from '../components/shared/NoMatch';
import LogOutSuccess from '../components/shared/_auth/LogOutSuccess';
import OAuth2 from '../components/shared/_auth/OAuth2';

// =============================================================================
// :: Layout Pages
// =============================================================================
import AdminRoute from './routes/AdminRoute';
import PublicRoute from './routes/PublicRoute';

// =============================================================================
// :: Public Pages
// =============================================================================
// import PublicHomePage from '../pages/public/home/HomePage';
import PublicActivitiesPage from '../pages/public/publicActivity/publicActivitiesOverview/PublicActivitiesPage';
import PublicActivityDetailPage from '../pages/public/publicActivity/publicActivityDetail/PublicActivityDetailPage';
import Unauthorized from '../components/shared/_auth/Unauthorized';
import StartLogout from '../components/shared/_auth/StartLogout';

// =============================================================================
// :: Admin Pages
// =============================================================================
// Lazy loading components
const AdminHomePage = React.lazy(() => import('../pages/admin/home/HomePage'));

const AdminCustomerMutatePage = React.lazy(() => import('../pages/admin/customer/customerMutate/CustomerMutatePage'));
const AdminCustomerDetailPage = React.lazy(() => import('../pages/admin/customer/customerDetail/CustomerDetailPage'));
const AdminActivityMutatePage = React.lazy(() => import('../pages/admin/activity/activityMutate/ActivityMutatePage'));
const AdminDomainMutatePage = React.lazy(() => import('../pages/admin/settings/domainMutate/DomainMutatePage'));
const AdminOrganisationMutatePage = React.lazy(() => import('../pages/admin/settings/organisationMutate/OrganisationMutatePage'));
const AdminDepartmentMutatePage = React.lazy(() => import('../pages/admin/settings/departmentMutate/DepartmentMutatePage'));
const AdminLocationMutatePage = React.lazy(() => import('../pages/admin/settings/locationMutate/LocationMutatePage'));
const AdminActivityTypeMutatePage = React.lazy(() => import('../pages/admin/settings/activityTypeMutate/ActivityTypeMutatePage'));
const AdminSettingsPage = React.lazy(() => import('../pages/admin/settings/settingsOverview/SettingsOverviewPage'));
const ActivityDetailPage = React.lazy(() => import('../pages/admin/activity/activityDetail/ActivityDetailPage'));
const AdminTeacherMutatePage = React.lazy(() => import('../pages/admin/settings/teacherMutate/TeacherMutatePage'));

Modal.setAppElement('#root'); class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Redirect exact from="/visits" to="/admin?tab=visits" />

          {/* PUBLIC */}
          <PublicRoute exact path="/" component={() => <Redirect to="/public/activities" />} />
          <PublicRoute exact path="/public" component={() => <Redirect to="/public/activities" />} />
          {/* <PublicRoute exact path="/public" component={PublicHomePage} heroActive /> */}
          <PublicRoute exact path="/public/activities" component={PublicActivitiesPage} />
          {/* <PublicRoute exact path="/public/activities/:activityTypeId" component={ActivityDetailPage} /> */}
          <PublicRoute exact path="/public/activities/:activityId" component={PublicActivityDetailPage} />

          {/* ADMIN */}
          {/* Overviewpages */}
          <AdminRoute exact path="/admin" acl="overview:view" component={AdminHomePage} />
          <AdminRoute exact path="/admin/settings" acl="settings:view" component={AdminSettingsPage} />

          {/* Add forms */}
          <AdminRoute exact path="/admin/customers/add" acl="customer:create" component={AdminCustomerMutatePage} />
          <AdminRoute exact path="/admin/activities/add" acl="activity:create" component={AdminActivityMutatePage} />
          <AdminRoute exact path="/admin/domains/add" acl="domain:create" component={AdminDomainMutatePage} />
          <AdminRoute exact path="/admin/organisations/add" acl="organisation:create" component={AdminOrganisationMutatePage} />
          <AdminRoute exact path="/admin/departments/add" acl="department:create" component={AdminDepartmentMutatePage} />
          <AdminRoute exact path="/admin/locations/add" acl="location:create" component={AdminLocationMutatePage} />
          <AdminRoute exact path="/admin/categories/add" acl="category:create" component={AdminActivityTypeMutatePage} />
          <AdminRoute exact path="/admin/teachers/add" acl="teacher:create" component={AdminTeacherMutatePage} />

          {/* Detailpages */}
          <AdminRoute exact path="/admin/activities/:activityId" acl="activity:view" component={ActivityDetailPage} />
          <AdminRoute exact path="/admin/customers/:customerId" acl="customer:view" component={AdminCustomerDetailPage} />

          {/* Edit forms */}
          <AdminRoute exact path="/admin/customers/:customerId/edit" acl="customer:edit" component={AdminCustomerMutatePage} />
          <AdminRoute exact path="/admin/activities/:activityId/edit" acl="activity:edit" component={AdminActivityMutatePage} />
          <AdminRoute exact path="/admin/organisations/:organisationId/edit" acl="organisation:edit" component={AdminOrganisationMutatePage} />
          <AdminRoute exact path="/admin/departments/:departmentId/edit" acl="department:edit" component={AdminDepartmentMutatePage} />
          <AdminRoute exact path="/admin/domains/:domainId/edit" acl="domain:edit" component={AdminDomainMutatePage} />
          <AdminRoute exact path="/admin/locations/:locationId/edit" acl="location:edit" component={AdminLocationMutatePage} />
          <AdminRoute exact path="/admin/categories/:categoryId/edit" acl="category:edit" component={AdminActivityTypeMutatePage} />
          <AdminRoute exact path="/admin/teachers/:teacherId/edit" acl="teacher:edit" component={AdminTeacherMutatePage} />

          {/* Shared */}
          <Route exact path="/oauth2" component={OAuth2} />
          <Route exact path="/start-logout" component={StartLogout} />
          <Route exact path="/logout-success" component={LogOutSuccess} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export default App;
