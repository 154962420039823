import React from 'react';
import DatePicker from 'react-date-picker';
import classNames from 'classnames';
import moment, { isDate } from 'moment';
import { isArray } from 'lodash-es';

export default ({
  name,
  label,
  value,
  required,
  minDate,
  maxDate,
  selectRange,
  disableCalendar,
  disabled,
  calenderIcon,
  className,

  onChange,
}) => {
  const datePickerTime = isArray(value) || isDate(value)
    ? value : value ? moment(value).toDate() : null;
  return (
    <div className="c-datepicker">
      {label && <label className={classNames('a-input__label', { 'a-input__label--required': required })}>{label}</label>}
      <DatePicker
        disabled={disabled}
        className={className}
        showLeadingZeros={false}
        name={name}
        value={datePickerTime}
        onChange={(e) => onChange({ target: { name, value: e } })}
        calendarIcon={calenderIcon || <i className="fa fa-calendar u-text-grey" />}
        clearIcon={<i className="fa fa-times u-text-grey u-text-light" />}
        locale="nl-BE"
        format="d-M-y"
        required={required}
        maxDate={maxDate}
        minDate={minDate}
        selectRange={selectRange}
        disableCalendar={disableCalendar}
      />
    </div>
  );
};
