import gql from 'graphql-tag';

export const fetchActivityList = gql`
  query fetchActivityList($where: ActivityWhereInput!, $skip: Int, $take: Int, $orderBy: ActivityOrderByInput!) {
    activities(
      where: $where, 
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      id
      start
      end
      name
      maxParticipants
      abbreviation
      locationName
      canceled
      activityType {
        name
      }
      price
      registrations(take: 9999) { 
        id
        canceled
      }
    }
    activityCount(where: $where)
  }
`;

export const fetchActivityReport = gql`
  query fetchActivityReport($where: ActivityWhereInput!, $skip: Int, $take: Int, $orderBy: ActivityOrderByInput!) {
    activities(
      where: $where, 
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      start
      end
      canceled
      name
      maxParticipants
      abbreviation
      locationName
      activityTypeName
      price
      registrations { 
        id
        canceled
      }
    }
  }
`;

export const fetchActivitiesByWhereInput = gql`
query fetchActivitiesByWhereInput($where: ActivityWhereInput!) {
  activities(
    where: $where
    take: 50
    orderBy: {start: DESC}
  ) {
    id
    name
    content
    start
    end
  }
}`;

export const createOneActivity = gql`
  mutation ($activityCreateInput: ActivityCreateInput!) {
    createOneActivity(data: $activityCreateInput) {
      id
    }
  }
`;

export const updateOneActivity = gql`
  mutation ($activityId: String!, $activityUpdateInput: ActivityUpdateInput!) {
    updateOneActivity(data: $activityUpdateInput, where: {id: $activityId} ) {
      id
    }
  }
`;

export const deleteOneActivityById = gql`
  mutation ($activityId: String!) {
    deleteOneActivity(where: {id: $activityId} ) {
      id
    }
  }
`;

export const fetchOneActivityById = gql`
  query fetchOneActivityById($activityId: String!) {
    activity(where: { id: $activityId }) {
      activityType {
        id
        name
      }
      id
      start
      end
      name
      abbreviation
      content
      priceType
      priceTypeNA
      priceTypeVT
      price
      priceNA
      priceVT
      minParticipants
      maxParticipants
      publicNote
      publiclyAvailable
      published
      publicationTime
      remark
      courseNumber
      canceled
      notifyOnCancel
      createdBy
      createdAt
      updatedBy
      updatedAt
      organisation {
        id
        name
      }
      department {
        id
        name
        subscribeDescription
      }
      lessons(orderBy: {start: ASC}) {
        id
        start
        end
        canceled
        notifyOnCancel
        teachers {
          id
          firstname
          lastname
        }
        teacher1
        teacher2
        classroom {
          id
          name
          location {
            id
            name
            telephone
            mobile
            address {
              street
              number
              city
              zip
            }
          }
        }
      }
      notifications {
        id
        subject
        message
        event
        channel
      }
    }
    registrationCount(where: { AND: [
      { activity: { id: { equals: $activityId } } }, 
      { canceled: { equals: false } }
    ]})
  }`;

export const fetchActivitySmsTasks = gql`
query fetchActivitySmsTasks($activityId: String!, $take: Int, $skip: Int) {
  activity(
    where: {id: $activityId}
    ) {
    smsTasks (
        take: $take
        skip: $skip
    ) {
      id
      createdAt
      createdBy
      message
      origin
      status
      recipients {
        id
        firstname
        lastname
        email
        cell
        telephone
      }
    }
  }
  smsTaskCount(
    where: { activity: { id: { equals: $activityId } } }
  )
}`;

export const fetchActivityEmailTasks = gql`
query fetchActivityEmailTasks($activityId: String!, $take: Int, $skip: Int) {
  activity(
    where: {id: $activityId}
    ) {
    emailTasks(
        take: $take
        skip: $skip
    ) {
      id
      createdAt
      subject
      createdBy
      message
      origin
      status
      recipients {
        id
        firstname
        lastname
        email
        cell
        telephone
      }
    }
  }
  emailTaskCount(
    where: { activity: { id: { equals: $activityId } } }
  )
}`;

export const fetchActivityAttendeeList = gql`
  query fetchActivityAttendeeList($activityId: String!) {
    activity(
      where: { id: $activityId }
    ) {
      name
      attendeeList
    }
  }`;

export const fetchOverlappingActivities = gql`
 query($where: ActivityWhereInput!) {
  activities(where: $where) {
    id
    name
    start
    end
    lessons {
      canceled
      start
      end
    }
  }
}`;
