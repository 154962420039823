import { isNaN, parseInt } from 'lodash-es';
import moment from 'moment';

export const generateStringFilter = (name, search = '') => {
  return [
    { [name]: { contains: search, mode: 'insensitive' } },
  ];
};

export const generateDateFilter = (name, date) => {
  const format = 'YYYY-MM-DD';
  const timestamp = 'T21:59:59.000Z';
  return [{
    [name]: {
      gt:
        `${moment(date)
          .subtract(1, 'day')
          .format(format)}${timestamp}`,
    },
  }, {
    [name]: {
      lt:
        `${moment(date)
          .format(format)}${timestamp}`,
    },
  }];
};

export const generateIntFilter = (name, search) => {
  if (!isNaN(parseInt(search))) {
    return [
      { [name]: { equals: parseInt(search) } },
    ];
  }
  return [];
};

export const generateDateBetweenFilter = (start, end, nameStart = 'start') => {
  const format = 'YYYY-MM-DD';
  const timestamp = 'T23:59:59.000Z';
  if (!start) {
    start = moment().subtract(99, 'year');
  }
  if (!end) {
    end = moment(start).add(99, 'year');
  }
  return [{
    // take date of oldest activity as default startdate
    [nameStart]: {
      gt: `${moment(start)
        .subtract(1, 'day')
        .format(format)}${timestamp}`,
    },
  }, {
    // take today as end when no end is given
    [nameStart]: {
      lt:
      `${moment(end)
        .format(format)}${timestamp}`,
    },
  }];
};
