import React from 'react';
import { map } from 'lodash-es';

const InfoList = ({
  className = '',
  children,
}) => {
  return (
    children && (
      <ul className={`c-info-list ${className}`}>
        {children.length ? map(children, (child, index) => {
          return (
            <li
              key={index}
              className="c-info-list__item"
            >
              {child}
            </li>
          );
        }) : (
          <li
            className="c-info-list__item"
          >
            {children}
          </li>
        )}
      </ul>
    )
  );
};

export default InfoList;
