import React from 'react';

const Alert = ({
  text,
  className = '',
}) => {
  return (
    <div
      className={`c-alert ${className}`}
    >
      <i className="fas fa-exclamation-triangle c-alert__icon" />

      <p className="c-alert__text">
        { text }
      </p>
    </div>
  );
};

export default Alert;
