import React, { useEffect, useState } from 'react';
import { debounce, isNumber, upperFirst } from 'lodash-es';
import renderHTML from 'react-render-html';
import Hero from '../../../../components/public/Hero';
import image from '../../../../assets/images/image_home.jpg';
import Info from '../../../../components/public/Info';
import InfoList from '../../../../components/public/InfoList';
import Alert from '../../../../components/public/Alert';
import Progress from '../../../../components/public/Progress';
import Popup from '../../../../components/public/Popup';
import { WithLoadingAndError } from '../../../../components/shared/_hoc/withLoadingAndError';
import Map from '../../../../components/shared/Map';

const ActivityDetailTemplate = ({
  activity = {},
  loadingActivity,
  error,
}) => {
  const withMap = activity.locationAddress !== 'N/A';
  const [progressColumns, setColumns] = useState(3);
  const [showPopup, setShowPopup] = useState(false);

  const calculatePrices = (price, priceType) => {
    if (price === null) return null;
    if (priceType === 'ACTIVITY') return price;
    return price * (activity.lessons?.length || 1);
  };

  const activityPrice = calculatePrices(activity.price, activity.priceType);
  const activityPriceNA = calculatePrices(activity.priceNA, activity.priceTypeNA);

  const handleResize = () => {
    if (window.innerWidth <= 960) {
      setColumns(2);
    } else {
      setColumns(3);
    }
  };

  useEffect(() => {
    handleResize();

    if (activity.lessons) {
      if (activity.lessons.length <= 4) {
        setColumns(1);
      } else if (activity.lessons.length <= 6) {
        setColumns(2);
      }
    }

    window.addEventListener('resize', debounce(handleResize, 150));
    return () => {
      window.removeEventListener('resize', debounce(handleResize, 150));
    };
  }, [activity]);

  if ((activity.occupationPercent > 0.7 && activity.occupationPercent !== 1) && !showPopup) {
    setTimeout(() => setShowPopup(true), 5000);
  }
  return (
    <div className="p-activity-detail">
      {showPopup && (
        <Popup
          text="Wees snel, want de plaatsen zijn beperkt en al bijna uitverkocht!"
        />
      )}
      {/* Hero */}
      <Hero
        className="c-hero--small-spacing"
        image={image}
        backButton
      />

      {/* General */}
      <WithLoadingAndError loading={loadingActivity} error={error} className="u-margin-left-xx@above-viewport-7">
        <div className="l-section l-container">
          <div className="p-activity-detail__title-wrapper u-margin-bottom-lg">
            <h1 className="p-activity-detail__title">
              {upperFirst(activity.name)}
            </h1>
          </div>
          <div className="l-section__content">
            {/* Desktop */}
            <InfoList className="p-activity-detail__intro-list u-hide@below-viewport-7">
              <Info icon={<i className="fas fa-pencil-alt" />}>
                {activity.subscribeDescription && renderHTML(activity.subscribeDescription)}
              </Info>
              <Info icon={<i className="fas fa-users" />}>
                <b>Bezetting</b><br />{activity.occupationPercent >= 1 ? (
                  <span className="u-text-red u-text-bold">Volzet {activity.occupation}</span>
                ) : activity.occupation}
              </Info>
            </InfoList>

            {/* Mobile */}
            <div className="p-activity-detail__info-list u-hide@above-viewport-7">
              <Info icon={<i className="fas fa-pencil-alt" />}>
                {activity.subscribeDescription && renderHTML(activity.subscribeDescription)}
              </Info>
              <Info icon={<i className="fas fa-users" />}>
                <b>Bezetting</b><br />{activity.occupationPercent >= 1 ? (
                  <span className="u-text-red u-text-bold">Volzet {activity.occupation}</span>
                ) : activity.occupation}
              </Info>
            </div>
          </div>
        </div>

        <section className="l-section l-container p-activity-detail__section">
          <header className="l-section__header u-hide@above-viewport-7">
            <h2 className="l-section__title">Beschrijving</h2>
          </header>

          <div className="l-section__content">
            <p className="p-activity-detail__intro-text">
              <b>{activity.content}</b>
            </p>

            {activity.publicNote && <Alert text={activity.publicNote} />}
          </div>
        </section>

        <div className="l-container p-activity-detail__content">
          <div className="l-grid l-grid--with-spacing">
            <section className={`l-section l-grid__col--12 ${withMap ? '' : 'l-grid__col--6@viewport-7'}`}>
              <header className="l-section__header">
                <h2 className="l-section__title">Praktische info</h2>
              </header>

              <div className="l-section__content p-activity-detail__map-flex">
                <div className={`p-activity-detail__info ${withMap ? 'p-activity-detail__info--no-right-radius' : ''}`}>
                  <div
                    className="p-activity-detail__info-list"
                  >
                    <Info
                      className="p-activity-detail__info-item"
                      icon={<i className="fas fa-location-arrow" />}
                    >
                      <b>Locatie</b><br />{activity.locationName}
                    </Info>

                    {/* <Info
                      className="p-activity-detail__info-item"
                      icon={<i className="fas fa-school" />}
                    >
                      <b>Leslokaal</b><br />{activity.classroom}
                    </Info> */}
                  </div>

                  <div className="p-activity-detail__info-list p-activity-detail__info-item">
                    <InfoList>
                      <Info
                        icon={<i className="fas fa-calendar-alt" />}
                      >
                        <b>Begindatum</b><br />{activity.start}

                      </Info>

                      <span><b>Einddatum</b><br />{activity.end}</span>
                    </InfoList>
                  </div>

                  <InfoList className="u-hide@below-viewport-9">
                    <Info
                      icon={<i className="fas fa-coins" />}
                    >
                      <b>Prijs</b><br />€ {activityPrice}
                    </Info>

                    {isNumber(activityPriceNA) && <span><b>Prijs Niet-Antwerpenaren</b><br />€ {activityPriceNA}</span>}
                  </InfoList>

                  <Info
                    className="u-hide@above-viewport-9"
                    icon={<i className="fas fa-coins" />}
                  >
                    <b>Prijs</b><br />€ {activityPrice}<br />
                    {isNumber(activityPriceNA) && <span><b>Prijs Niet-Antwerpenaren</b><br />€ {activityPriceNA}</span>}
                  </Info>
                </div>

                {withMap && (
                  <Map
                    className="p-activity-detail__map"
                    place={activity.locationName}
                    address={activity.locationAddress}
                    telephone={activity.locationContact}
                  />
                )}
              </div>
            </section>

            <section className={`l-section l-grid__col--12 p-activity-detail__section ${withMap ? '' : 'l-grid__col--6@viewport-7'}`}>
              <header className="l-section__header">
                <h2 className="l-section__title">Lessen</h2>
              </header>

              <div className="l-section__content p-activity-detail__progress">
                <Progress
                  className={withMap ? 'c-progress--with-map' : 'c-progress--no-map'}
                  columns={withMap ? progressColumns : 2}
                  lessons={activity.lessons}
                />
              </div>
            </section>
          </div>
        </div>
      </WithLoadingAndError>
    </div>
  );
};

export default ActivityDetailTemplate;
